<script setup lang="ts">
  const colorMode = useColorMode()
</script>

<template>
  <div class="min-h-screen w-full">
    <div class="relative h-full">
      <!-- Background Pattern (Dark Mode) -->
      <div v-if="colorMode.value === 'dark'" class="absolute inset-0 -z-10">
        <div class="relative h-full w-full -z-10 bg-slate-950 [&>div]:absolute [&>div]:inset-0 [&>div]:bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] [&>div]:bg-[size:14px_24px] [&>div]:[mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]">
          <div />
        </div>
      </div>

      <!-- Background Pattern (Light Mode) -->
      <div v-if="colorMode.value === 'light'" class="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]">
        <!-- <div class="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-primary opacity-20 blur-[100px]" /> -->
      </div>

      <!-- Page Content -->
      <HeaderNavModern />
      <main class="min-h-[calc(100vh-var(--header-height))]">
        <slot />
      </main>
      <!-- <footer class=" bottom-0 left-0 right-0 h-16 bg-red-500">
        Footer
      </footer> -->
    </div>
  </div>
</template>

<style scoped>

</style>
